import styled from 'styled-components'
import { TextInput } from 'grommet'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-family: 'Rammetto One', regular;
`

export const Box = styled.div`
  background-color: #341644;
  border-radius: 20px;
  color: #fff;
  width: 280px;
  padding: 25px;
  > :last-child {
    position: relative;
    margin-left: 35%;
  }
`

export const MainTitle = styled.h1`
  margin-top: 0px;
  padding-top: 40px;
  font-size: 20px;
  text-align: center;
  line-height: 45px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 2px #afafaf, 0px 5px rgba(0, 0, 0, 0.3);
`

export const SubTitle = styled.div`
  margin-bottom: 30px;
`
export const Info = styled.div`
  font-size: 14px;
  font-family: 'Libre Baskerville';
  text-align: left;
  margin-bottom: 10px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #341644;
  border-radius: 8px;
  margin-bottom: 20px;
  ${Info} {
    margin-right: auto;
  }
`

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

export const Label = styled.label`
  font-size: 16px;
  font-weight: normal;
  text-align: left;
`

export const Input = styled(TextInput)`
  margin-top: 8px;
  height: 45px;
  font-size: 18px;
  background-color: #fff;
  color: #000000;
  border-radius: 20px;
  text-align: center; 
  font-size: 17px;
  font-family: 'Libre Baskerville';
`

export const Errors = styled.ul`
  color: #EF4D42;
  padding: 0;
  list-style-type: none;
`

export const Error = styled.li`

`

export const Expired = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  padding: 0px 20px;
  color: #FFFFFF;
  > :first-child {
    font-size: 34px;
    line-height: 45px;
    text-align: center;
    margin-bottom: 50px;
  }
  > :nth-child(2) {
    margin-bottom: 15px;
  }
  font-family: 'Rammetto One', regular;
`

export const Abilities = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  > :first-child { 
    position: relative;
  }
  > :nth-child(2) { 
    position: relative;
    margin-left: 10px;
    margin-top: 5px;
  }
`

export const Link = styled(Info)`
  text-decoration: underline;
  cursor: pointer;
  font-size: 20px;
  left: -10%;
`