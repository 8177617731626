import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled('div')`
  font-family: 'Libre Baskerville', serif;
`

const DropDownList = styled('select')`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #000000;
  font-size: 1.3rem;
  border-radius: 20px;
  font-weight: 500;
  width: ${(props: { width?: number; height: number }) =>
    props.width ? `${props.width}px` : '100%'};
  height: ${(props: { width?: number; height: number }) => props.height}px;
  > * { height: 20px; }
  text-align: center;
  font-size: 17px;
  outline: none;
  font-family: 'Libre Baskerville';
`

interface DropDownProps {
  options: { value: any; label: string }[]
  onChange: Function
  value: any
  width?: number
  height: number
}

const DropDown = (props: DropDownProps) => {
  const [selectedOption, setSelectedOption] = useState(props.value)
  const { options } = props

  useEffect(() => {
    props.onChange(props.value)
  }, [])
  const makeItem = (item, value) => <option key={value} value={value}>{item}</option>

  const onOptionClicked = (e) => {
    props.onChange(e.target.value)
    setSelectedOption(e.target.value)
  }

  return (
    <Wrapper>
      <DropDownList
        value={selectedOption}
        onChange={onOptionClicked}
        height={props.height}
        width={props.width}
      >
        { options.map((option) => makeItem(option.label, option.value)) }
      </DropDownList>
    </Wrapper>
  )
}

DropDown.defaultProps = {
  width: null,
}

export default DropDown
