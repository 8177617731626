import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Tick } from '../../../assets/images/icons/icon-tick.svg'

interface Props {
  height?: number,
  width?: number,
  size?: number
  color?: string,
}

export const Icon = styled.div`
  height: ${(props: Props) => props.size || props.height}px;
  width: ${(props: Props) => props.size || props.width}px;
  position: absolute;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  background-color: ${(props: Props) => props.color };
  z-index: 2;
`

const StyledTick = styled.div`
  svg {
    height: ${(props: Props) => props.size * .85 || props.height}px;
    width: ${(props: Props) => props.size * .85 || props.width}px;
    margin-left: 10%;
    margin-top: 10%;
  }
`


export default (props: Props) => 
  <Icon {...props}>
    <StyledTick  {...props}>
      <Tick />
    </StyledTick>
  </Icon>
