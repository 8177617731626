import { useApolloClient } from '@apollo/client'
import { useEffect, useState } from "react"
import { CurrentUserState } from "../../../state/CurrentUserState"
// @ts-ignore
import CAN_CREATE_PASSWORD from './canCreatePassword.graphql'
// @ts-ignore
import CREATE_PASSWORD from './createPassword.graphql'
// @ts-ignore
import GET_DATA from './getData.graphql'

export default (_id, hash, kidFirst) => {
  const client = useApolloClient()
  const [name, setName] = useState()
  const { login } = CurrentUserState.useContainer()
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(true)
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [canSubmit, setCanSubmit] = useState(false)
  const [expired, setExpired] = useState(false)
  const [country, setCountry] = useState(null)
  const [cannotCreate, setCannotCreate] = useState(false)
  const [KidFirst, setKidFirst] = useState(kidFirst)
  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    checkPassword()
    // eslint-disable-next-line
  }, [password1, password2])

  useEffect(() => {
    setCanSubmit(password1.length > 0 && password2.length > 0 && errors.length === 0)
    // eslint-disable-next-line
  }, [errors])

  const init = async () => {
    setLoading(true)
    try {
      const { data } = await client.query({
        query: CAN_CREATE_PASSWORD,
        variables: { _id, hash },
        errorPolicy: 'all'
      })
      if (data.canCreatePassword) {
        setName(data.canCreatePassword.name)
        setCannotCreate(false)
      } else {
        setCannotCreate(true)
      }
    } catch (err) { setExpired(true) }
    setLoading(false)
    // getData()
  }

  const getData = async() => {
    const { data } = await client.query({
      query: GET_DATA,
      variables: {
        id: _id
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    })
    if(data) {
      setCountry(data.findUser.children[0].countryCode)
      setLoading(false)
    }
  }
  
  const checkPassword = () => {
    let errors = []
    if (password2.length > 0 && password1.length < 6) {
      errors = [...errors, 'Password should be at least 6 characters']
    }
    if (password2 && password1 !== password2) {
      errors = [...errors, 'Passwords don\'t match']
    }
    setErrors(errors)
  }

  const onPassword1Change = (e) => {
    setPassword1(e.target.value)
  }

  const onPassword2Change = (e) => {
    setPassword2(e.target.value)
  }

  const onCountryChange = (value) => {
    setCountry(value)
  }

   // ! Wtf is this
  const submitCountry = async (sharedSecretKey) => {
    const { data } = await client.mutate({
      mutation: CREATE_PASSWORD,
      variables: { _id, country: country }
    })
    if(data) {
      login(_id, sharedSecretKey, true)
    }
  }

  const onSubmit = async () => {
    localStorage.setItem('should-show-welcome-video', 'true')
    const { data } = await client.mutate({
      mutation: CREATE_PASSWORD,
      variables: { _id, hash, password: password1 }
    })
    if (data.createPassword) {
      const { _id, sharedSecretKey } = data.createPassword
      // submitCountry()
      login(_id, sharedSecretKey, false)
    }
  }

  const onKidFirstSubmit = async () => {
    localStorage.setItem('should-show-welcome-video', 'true')
    const { data } = await client.mutate({
      mutation: CREATE_PASSWORD,
      variables: { _id, hash, password: password1 }
    })
    if (data.createPassword) {
      const { _id, sharedSecretKey } = data.createPassword
      // submitCountry(sharedSecretKey)
      login(_id, sharedSecretKey, true)
    }
  }

  return { name, loading, errors, country, cannotCreate, onPassword1Change, onPassword2Change, onCountryChange, onSubmit, onKidFirstSubmit, canSubmit, expired, KidFirst }
}