import { navigate } from "gatsby"
import { Button } from 'nzk-react-components'
import React, { useEffect, useRef } from "react"
import { ThemeState } from "../../../state/ThemeState"
import Loader from "../../UI/Loader"
import {
  Box, Error, Errors,
  Expired, Form,
  FormField, Info, Input, Label, Link, MainTitle, Wrapper
} from "./index.styles"
import KidFirstSignUp from './kidFirstSignUp'
import useCreatePassword from "./useCreatePassword"
/*global amplitude*/

const CreatePasswordPage = props => {
  const urlParams = new URLSearchParams(window.location.search)
  const password2Ref = useRef()
  const {
    loading,
    name,
    errors,
    onPassword1Change,
    onPassword2Change,
    onCountryChange,
    onSubmit,
    canSubmit,
    expired,
    cannotCreate,
    KidFirst
  } = useCreatePassword(urlParams.get("userId"), urlParams.get("hash"), urlParams.get("kidFirst"))
  const { theme } = ThemeState.useContainer()

  useEffect(() => {
    if (typeof amplitude !== 'undefined') {
      amplitude.getInstance().logEvent('Dashboard: View password creation page')
    }
  }, [])

  if (loading) return <Loader />


  if (false && expired)
    return (
      <Expired>
        <div>Your account has already been created!</div>
        <Button
            onClick={() => navigate('/login')}
            backgroundColor={theme.primary}
            height={60}
            size='small'
          >
          Login
        </Button>
      </Expired>
    )
  if(KidFirst == 1) return < KidFirstSignUp />
  if(false && cannotCreate) {
    return <Wrapper>
      <MainTitle>You already have an account.</MainTitle>
      <Box background="#341644" width="280px" elevation="medium" pad="medium" round="small">
        <Link onClick={() => {navigate("/login")}}>
          Please sign in.
        </Link>
      </Box>
    </Wrapper>
  } else {
      return (
      <Wrapper>
        <MainTitle>Thank You</MainTitle>
        <Box background="#341644" width="280px" elevation="medium" pad="medium" round="small">
          {/* <Info>
            Set up a password to create your dashboard. You will then be able to :
          </Info>
          <Abilities>
            <FlatTick color="#8CC63F" size="28" />
            <Info>Monitor your child's progress</Info>
          </Abilities>
          <Abilities>
            <FlatTick color="#8CC63F" size="28" />
            <Info>Sign up for an extended trial</Info>
          </Abilities>
          <Abilities>
            <FlatTick color="#8CC63F" size="28" />
            <Info>Add more children</Info>
          </Abilities> */}
          <Form onSubmit={onSubmit} >
            <Errors>
              {errors.map((e, i) => (
                <Error key={i}>{e}</Error>
              ))}
            </Errors>
            <Input type="email" hidden autoComplete="email" />
            <FormField>
              <Label>Choose a password</Label>
              <Input
                type="password"
                placeholder="Password"
                onChange={onPassword1Change}
                autoComplete="new-password"
                onKeyPress={e =>
                  e.which === 13 &&
                  password2Ref.current &&
                  password2Ref.current.focus()
                }
              />
            </FormField>
            <FormField>
              <Input
                ref={password2Ref}
                type="password"
                placeholder="Confirm Password"
                onChange={onPassword2Change}
                autoComplete="new-password"
                onKeyPress={e => e.which === 13 && canSubmit && onSubmit()}
              />
            </FormField>
            <Info>
              Minimum 6 characters
            </Info>
            </Form>
            {/* <Info>Please Select your country</Info>
            <Form>
              <DropDown value='US' options={Countries} onChange={onCountryChange} toggled={true} width={220} height={45}/>
            </Form> */}
            <Button
              onClick={onSubmit}
              backgroundColor='#8CC63F'
              disabled={!canSubmit}
              height={30}
              size='small'
            >
              Next
            </Button>
        </Box>
      </Wrapper>
    )
  }
}

CreatePasswordPage.propTypes = {}

CreatePasswordPage.defaultProps = {}

export default CreatePasswordPage