import React from "react"
import CreatePasswordPage from '../components/pages/CreatePasswordPage'
import UnauthenticatedLayout from "../layouts/UnauthenticatedLayout"

const CreatePassword = props => {
  if (typeof window === "undefined") return null
  return <CreatePasswordPage {...props} />
}

CreatePassword.propTypes = {}

CreatePassword.defaultProps = {}

const CreatePasswordPagePage = props => {
  if (typeof window === "undefined") return null
  return (
    <UnauthenticatedLayout>
      <CreatePassword {...props} />
    </UnauthenticatedLayout>
  )
}

export default CreatePasswordPagePage
